import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing }: Theme) => ({
  formFields: {
    margin: spacing(2),
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    margin: spacing(1),
  },
  underlinedText: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
