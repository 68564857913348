import { gql, useMutation } from '@apollo/client';

import { MutationHook } from '../../../types';

export const UPDATE_ORGANIZATIOM = gql`
  mutation updateOrganization($data: OrganizationInput!) {
    updateOrganization(data: $data) {
      _id
      name
      apiNamespace
    }
  }
`;

export const useUpdateOrganization: MutationHook<'onUpdateOrganization'> = (
  options = {}
) => {
  const [onUpdateOrganization] = useMutation(UPDATE_ORGANIZATIOM, options);

  return { onUpdateOrganization };
};
