import React from 'react';
import { Save, Delete, Mail } from '@material-ui/icons';

import { OrganizationProps } from '../../../types';
import { useOrganization } from '../../../presenter/Organization/useOrganization';
import { getError } from '../../DataSources/helpers';
import { Input, WidgetBody, Col, Grid, IconButton, Tooltip } from '../../../ui';
import { Loading } from '../../Loading';

import { EmptyOrganization } from './EmptyOrganization';
import { InviteUser } from '../InviteUser';
import { DeleteOrganization } from '../DeleteOrganization';
import { UsersList } from './UsersList';
import { useStyles } from './useStyles';

export const OrganizationTab: React.FC<OrganizationProps> = ({
  goToInvites,
}) => {
  const {
    data,
    onCreate,
    onDelete,
    onUpdate,
    onInvite,
    loading,
    register,
    errors,
    isOwner,
  } = useOrganization();
  const hasErrors = getError(errors);
  const { formFields, icon, icons } = useStyles();

  const users = data?.users || [];

  if (loading) return <Loading />;

  if (!data) {
    return <EmptyOrganization goToInvites={goToInvites} onCreate={onCreate} />;
  }

  return (
    <>
      <WidgetBody>
        <form action="submit">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Col xs={10}>
              <Input
                ref={register}
                className={formFields}
                label="Organization name"
                name="name"
                disabled={!isOwner}
                error={hasErrors('name')}
                InputProps={{
                  autoComplete: 'off',
                }}
              />
              <Input
                ref={register}
                className={formFields}
                label="API namespace"
                name="apiNamespace"
                disabled={!isOwner}
                error={hasErrors('apiNamespace')}
                InputProps={{
                  autoComplete: 'off',
                }}
              />
            </Col>
            <Col className={icons} xs={2}>
              {isOwner && (
                <>
                  <Tooltip
                    title="Update organization"
                    placement="right"
                    aria-label="update organization"
                  >
                    <IconButton size="small" onClick={onUpdate}>
                      <Save className={icon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Delete organization"
                    placement="right"
                    aria-label="delete organization"
                  >
                    <IconButton size="small" onClick={onDelete}>
                      <Delete className={icon} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <Tooltip
                title="Invite user"
                placement="right"
                aria-label="invite user"
              >
                <IconButton size="small" onClick={onInvite}>
                  <Mail className={icon} onClick={onInvite} />
                </IconButton>
              </Tooltip>
            </Col>
          </Grid>
        </form>
        <UsersList data={users} />
      </WidgetBody>
      <DeleteOrganization />
      <InviteUser />
    </>
  );
};
