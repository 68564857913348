import React from 'react';

import { UsersListProps } from '../../../types';
import {
  WidgetBody,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  EmptyContainer,
  EmptyIcon,
  EmptyText,
} from '../../../ui';
import { getKeyFromText, alignFirstCellLeft } from '../../../utils';

const TABLE_HEAD = ['Email', 'First Name', 'Last Name'];

export const UsersList: React.FC<UsersListProps> = ({ data = [] }) => {
  if (!data.length)
    return (
      <EmptyContainer>
        <EmptyIcon />
        <EmptyText>Organization does not contains users</EmptyText>
      </EmptyContainer>
    );

  return (
    <>
      <WidgetBody>
        <Table>
          <TableHead>
            {TABLE_HEAD.map((cell, idx) => (
              <TableCell
                key={getKeyFromText(cell)}
                align={alignFirstCellLeft(idx)}
              >
                {cell}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {data.map(({ email, firstName, lastName }, idx: number) => (
              <TableRow key={`node-${idx}`}>
                <TableCell align="left">{email}</TableCell>
                <TableCell align="right">{firstName || '-'}</TableCell>
                <TableCell align="right">{lastName || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </WidgetBody>
    </>
  );
};
