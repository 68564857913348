import { gql, useMutation } from '@apollo/client';

import { MutationHook } from '../../../types';

export const INVITE = gql`
  mutation inviteToOrganization($email: String!) {
    inviteToOrganization(email: $email)
  }
`;

export const useInvite: MutationHook<'onInvite'> = (options = {}) => {
  const [onInvite] = useMutation(INVITE, options);

  return { onInvite };
};
