import { useQuery, gql } from '@apollo/client';

export const QUERY_ORGANIZATION_INVITES = gql`
  query getOrganizationInvites {
    getOrganizationInvites {
      _id
      name
      apiNamespace
      owner {
        _id
        email
      }
    }
  }
`;

export const useInvitesQuery = () => {
  const { data, loading, error, refetch } = useQuery(
    QUERY_ORGANIZATION_INVITES
  );

  return {
    data: data?.getOrganizationInvites,
    loading,
    error,
    refetch,
  };
};
