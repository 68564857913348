import React from 'react';
import { Check, Close } from '@material-ui/icons';

import { getKeyFromText, alignFirstCellLeft } from '../../../utils';
import { useInvites } from '../../../presenter/Organization';
import {
  WidgetBody,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
} from '../../../ui';
import { Loading } from '../../Loading';
import { EmptyInvites } from './EmptyInvites';

const TABLE_HEAD = [
  'Organization name',
  'Owner email',
  'API namespace',
  'Actions',
];

export const InvitesTab: React.FC = () => {
  const { data, loading, onAccept, onDecline } = useInvites();

  if (loading) return <Loading />;

  if (!data?.length) return <EmptyInvites />;

  return (
    <WidgetBody>
      <Table>
        <TableHead>
          {TABLE_HEAD.map((cell, idx) => (
            <TableCell
              key={getKeyFromText(cell)}
              align={alignFirstCellLeft(idx)}
            >
              {cell}
            </TableCell>
          ))}
        </TableHead>
        <TableBody>
          {data.map(({ owner, apiNamespace, name, _id }: any, idx: number) => (
            <TableRow key={`node-${idx}`}>
              <TableCell align="left">{name}</TableCell>
              <TableCell align="right">{owner?.email}</TableCell>
              <TableCell align="right">{apiNamespace}</TableCell>
              <TableCell align="right">
                <Tooltip
                  title="Accept invite"
                  placement="left"
                  aria-label="accept invite"
                >
                  <IconButton size="small" onClick={onAccept.bind(null, _id)}>
                    <Check />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="Decline invite"
                  placement="right"
                  aria-label="decline invite"
                >
                  <IconButton size="small" onClick={onDecline.bind(null, _id)}>
                    <Close />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </WidgetBody>
  );
};
