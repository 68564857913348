import { gql, useMutation } from '@apollo/client';

import { MutationHook } from '../../../types';

export const DELETE_ORGANIZATIOM = gql`
  mutation deleteOrganization($id: String!) {
    deleteOrganization(id: $id)
  }
`;

export const useDeleteOrganization: MutationHook<'onDeleteOrganization'> = (
  options = {}
) => {
  const [onDeleteOrganization] = useMutation(DELETE_ORGANIZATIOM, options);

  return { onDeleteOrganization };
};
