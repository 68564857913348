import React from 'react';
import { Helmet } from 'react-helmet';

import { Header, WidgetRow, TabsBody, TabsHead, HugeWidget } from '../../ui';
import { useTabs } from '../../model/Hooks';
import { OrganizationTab } from './OrganizationTab';
import { InvitesTab } from './InvitesTab';

const TABS = [{ label: 'Organization' }, { label: 'Invites' }];

export const Organization: React.FC = () => {
  const { tab, onChange } = useTabs();

  return (
    <>
      <Helmet>
        <title>Organization</title>
      </Helmet>
      <Header title="Organization" />
      <WidgetRow>
        <HugeWidget>
          <TabsHead
            tabsList={TABS}
            indicatorColor="primary"
            onChange={onChange}
            value={tab}
          />
          <TabsBody value={tab}>
            <OrganizationTab goToInvites={(e: any) => onChange(e, 1)} />
            <InvitesTab />
          </TabsBody>
        </HugeWidget>
      </WidgetRow>
    </>
  );
};
