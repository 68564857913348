import { useForm } from 'react-hook-form';
import vest, { test, enforce } from 'vest';
import { vestResolver } from '@hookform/resolvers/vest';

import { useFormErrors } from '../../model/Hooks';
import { useDialogs } from '../../model/providers';

const suite = vest.create('invite_user', ({ email }) => {
  test('email', 'Email is required', () => {
    enforce(email).isNotEmpty();
  });
  test('email', 'Please enter correct Email', () => {
    enforce(email).isEmail();
  });
});

const DEFAULT_INVITE_USER_STATE = {
  email: '',
};

export const useInviteUser = () => {
  const { dialogConfig } = useDialogs()!;
  const { handleSubmit, errors, register } = useForm({
    resolver: vestResolver(suite),
    reValidateMode: 'onSubmit',
    defaultValues: DEFAULT_INVITE_USER_STATE,
  });

  useFormErrors(errors);

  return {
    onSubmit: handleSubmit((data) => {
      dialogConfig.onSuccess(data.email);
    }),
    onCancel: dialogConfig.onCancel,
    errors,
    register,
  };
};
