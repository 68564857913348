import { gql, useMutation } from '@apollo/client';

import { MutationHook } from '../../../types';

export const CREATE_ORGANIZATIOM = gql`
  mutation createOrganization($data: OrganizationInput!) {
    createOrganization(data: $data) {
      _id
      name
      apiNamespace
    }
  }
`;

export const useCreateOrganization: MutationHook<'onCreateOrganization'> = (
  options = {}
) => {
  const [onCreateOrganization] = useMutation(CREATE_ORGANIZATIOM, options);

  return { onCreateOrganization };
};
