import { ReactNode } from 'react';

export const getMutationOptions = (
  success: string,
  {
    showSuccessToast,
    showErrorToast,
  }: {
    showSuccessToast: (messages: ReactNode) => void;
    showErrorToast: (messages: ReactNode) => void;
  },
  refetcbes: (() => any)[]
) => {
  return {
    onCompleted: async () => {
      showSuccessToast(success);
      await Promise.all(refetcbes.map((f) => f()));
    },
    onError: async (error: Error) => {
      showErrorToast(error.message);
      await Promise.all(refetcbes.map((f) => f()));
    },
  };
};
