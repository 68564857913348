import React from 'react';

import { useCreateOrganization } from '../../../presenter/Organization';
import { Input, DialogTitle, PrimaryButton, TextButton } from '../../../ui';
import { getError } from '../../DataSources/helpers';
import { ModalDialog, CREATE_ORGANIZATION } from '../../Dialogs';
import { useStyles } from './useStyles';

export const CreateOrganization: React.FC = () => {
  const { onSubmit, errors, onCancel, register } = useCreateOrganization();
  const { controller } = useStyles();
  const hasErrors = getError(errors);

  return (
    <>
      <ModalDialog id={CREATE_ORGANIZATION}>
        <DialogTitle>Create organization</DialogTitle>
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
          <Input
            ref={register}
            className={controller}
            fullWidth
            label="Organization name"
            name="name"
            error={hasErrors('name')}
          />
          <Input
            ref={register}
            className={controller}
            fullWidth
            label="API Namespace"
            name="apiNamespace"
            error={hasErrors('apiNamespace')}
          />
          <PrimaryButton fullWidth type="submit" className={controller}>
            Create
          </PrimaryButton>
        </form>
        <TextButton onClick={onCancel} type="reset">
          Cancel
        </TextButton>
      </ModalDialog>
    </>
  );
};
