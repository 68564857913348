import React from 'react';
import {
  Emoji,
  EmptyContainer,
  EmptyIcon,
  EmptyText,
  WidgetBody,
} from '../../../ui';

export const EmptyInvites: React.FC = () => {
  return (
    <WidgetBody>
      <EmptyContainer>
        <EmptyIcon />
        <EmptyText>
          You don't have invites at the moment{' '}
          <Emoji label="loudly crying face">😭</Emoji>
        </EmptyText>
      </EmptyContainer>
    </WidgetBody>
  );
};
