import { vestResolver } from '@hookform/resolvers/vest';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import vest, { test, enforce } from 'vest';
import {
  useOrganizationQuery,
  useDeleteOrganization,
  useCreateOrganization,
  useUpdateOrganization,
  useInvite,
} from '../../model/Organization';
import { useAuth, useDialogs, useToast } from '../../model/providers';
import {
  CREATE_ORGANIZATION,
  DELETE_ORGANIZATION,
  INVITE_USER,
} from '../../view/Dialogs';
import { getMutationOptions } from '../../utils';

const suite = vest.create('create_organization', ({ name, apiNamespace }) => {
  test('name', 'Name is required', () => {
    enforce(name).isNotEmpty();
  });
  test('apiNamespace', 'API Namespace is required', () => {
    enforce(apiNamespace).isNotEmpty();
  });
});

export const useOrganization = () => {
  const { userId } = useAuth();
  const toasts = useToast();
  const { data, refetch, loading } = useOrganizationQuery();

  const { name = '', apiNamespace = '', owner = {} } = data || {};

  const isOwner = useMemo(() => {
    return owner?._id === userId;
  }, [owner._id, userId]);

  const { handleSubmit, errors, register, reset } = useForm({
    reValidateMode: 'onSubmit',
    resolver: vestResolver(suite),
    defaultValues: {
      name,
      apiNamespace,
    },
  });

  useEffect(() => {
    reset({ name, apiNamespace });
  }, [name, apiNamespace, reset]);

  const { onOpenDialog, onCloseDialog } = useDialogs()!;

  // INVITE USER
  const { onInvite: onInviteHandler } = useInvite(
    getMutationOptions('User was successfully invited!', toasts, [refetch])
  );
  const onInvite = () => {
    onOpenDialog(INVITE_USER, {
      onSuccess: (email: string) => {
        onInviteHandler({ variables: { email } });
        onCloseDialog();
      },
      onCancel: onCloseDialog,
    });
  };

  // CREATE ORGANIZATION
  const { onCreateOrganization } = useCreateOrganization(
    getMutationOptions('Organization was successfully created!', toasts, [
      refetch,
    ])
  );
  const onCreate = () => {
    onOpenDialog(CREATE_ORGANIZATION, {
      onSuccess: (createData: any) => {
        onCreateOrganization({ variables: { data: createData } });
        onCloseDialog();
      },
      onCancel: onCloseDialog,
    });
  };

  // DELETE ORGANIZATION
  const { onDeleteOrganization } = useDeleteOrganization(
    getMutationOptions('Organization was successfully deleted!', toasts, [
      refetch,
    ])
  );
  const onDelete = () => {
    const { _id: id = '', name = '' } = data;
    onOpenDialog(DELETE_ORGANIZATION, {
      onSuccess: () => {
        onDeleteOrganization({ variables: { id } });
        onCloseDialog();
      },
      onCancel: onCloseDialog,
      name,
    });
  };

  // UPDDATE
  const { onUpdateOrganization } = useUpdateOrganization(
    getMutationOptions('Organization was successfully updated!', toasts, [
      refetch,
    ])
  );
  const onUpdate = (data: any) => {
    onUpdateOrganization({ variables: { data } });
  };

  return {
    data,
    loading,
    errors,
    register,
    onCreate,
    onDelete,
    onInvite,
    isOwner,
    onUpdate: handleSubmit(onUpdate),
  };
};
