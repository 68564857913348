import React from 'react';
import { EmptyContainer, EmptyIcon, EmptyText, WidgetBody } from '../../../ui';
import { CreateOrganization } from '../CreateOrganization';
import { useStyles } from './useStyles';
import { EmptyOrganizationProps } from '../../../types';

export const EmptyOrganization: React.FC<EmptyOrganizationProps> = ({
  goToInvites,
  onCreate,
}) => {
  const { underlinedText } = useStyles();
  return (
    <>
      <WidgetBody>
        <EmptyContainer>
          <EmptyIcon />
          <EmptyText>
            You are not a member of an organization. You can{' '}
            <span className={underlinedText} onClick={onCreate}>
              <b>create</b>
            </span>{' '}
            it or{' '}
            <span className={underlinedText} onClick={goToInvites}>
              <b>accept invite</b>
            </span>
          </EmptyText>
        </EmptyContainer>
      </WidgetBody>
      <CreateOrganization />
    </>
  );
};
