import { useQuery, gql } from '@apollo/client';

export const QUERY_ORGANIZATION = gql`
  query getOrganization {
    getOrganization {
      _id
      name
      apiNamespace
      users {
        firstName
        lastName
        email
        createdAt
      }
      owner {
        _id
      }
    }
  }
`;

export const useOrganizationQuery = () => {
  const { data, loading, error, refetch } = useQuery(QUERY_ORGANIZATION);

  return {
    data: data?.getOrganization,
    loading,
    error,
    refetch,
  };
};
