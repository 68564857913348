import {
  useInvitesQuery,
  useAcceptInvite,
  useDeclineInvite,
  useOrganizationQuery,
} from '../../model/Organization';
import { useToast } from '../../model/providers';
import { getMutationOptions } from '../../utils';

export const useInvites = () => {
  const toasts = useToast();
  const { data, refetch, loading } = useInvitesQuery();
  const { refetch: refetchOrganization } = useOrganizationQuery();

  // ACCEPT INVITE
  const { onAcceptInvite } = useAcceptInvite(
    getMutationOptions('Invite was successfully accepted!', toasts, [
      refetch,
      refetchOrganization,
    ])
  );
  const onAccept = (id: string) => {
    onAcceptInvite({ variables: { organizationId: id } });
  };

  // DECLINE INVITE
  const { onDeclineInvite } = useDeclineInvite(
    getMutationOptions('Invite was successfully declined!', toasts, [refetch])
  );
  const onDecline = (id: string) => {
    onDeclineInvite({ variables: { organizationId: id } });
  };

  return {
    data,
    loading,
    onAccept,
    onDecline,
  };
};
