import { gql, useMutation } from '@apollo/client';

import { MutationHook } from '../../../types';

export const DECLINE_INVITE = gql`
  mutation declineOrganizationInvite($organizationId: String!) {
    declineOrganizationInvite(organizationId: $organizationId) {
      _id
    }
  }
`;

export const useDeclineInvite: MutationHook<'onDeclineInvite'> = (
  options = {}
) => {
  const [onDeclineInvite] = useMutation(DECLINE_INVITE, options);

  return { onDeclineInvite };
};
