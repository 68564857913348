import { gql, useMutation } from '@apollo/client';

import { MutationHook } from '../../../types';

export const ACCEPT_INVITE = gql`
  mutation acceptOrganizationInvite($organizationId: String!) {
    acceptOrganizationInvite(organizationId: $organizationId) {
      _id
    }
  }
`;

export const useAcceptInvite: MutationHook<'onAcceptInvite'> = (
  options = {}
) => {
  const [onAcceptInvite] = useMutation(ACCEPT_INVITE, options);

  return { onAcceptInvite };
};
