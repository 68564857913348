import { useForm } from 'react-hook-form';
import vest, { test, enforce } from 'vest';
import { vestResolver } from '@hookform/resolvers/vest';

import { useFormErrors } from '../../model/Hooks';
import { useDialogs } from '../../model/providers';

const suite = vest.create('create_organization', ({ name, apiNamespace }) => {
  test('name', 'Name is required', () => {
    enforce(name).isNotEmpty();
  });
  test('apiNamespace', 'API Namespace is required', () => {
    enforce(apiNamespace).isNotEmpty();
  });
});

const DEFAUL_CREATE_ORGANIZATION_STATE = {
  name: '',
  apiNamespace: '',
};

export const useCreateOrganization = () => {
  const { dialogConfig } = useDialogs()!;
  const { handleSubmit, errors, register } = useForm({
    resolver: vestResolver(suite),
    reValidateMode: 'onSubmit',
    defaultValues: DEFAUL_CREATE_ORGANIZATION_STATE,
  });

  useFormErrors(errors);

  return {
    onSubmit: handleSubmit((data) => {
      dialogConfig.onSuccess({
        name: data.name,
        apiNamespace: data.apiNamespace,
      });
    }),
    onCancel: dialogConfig.onCancel,
    errors,
    register,
  };
};
