import React from 'react';

import { useInviteUser } from '../../../presenter/Organization';
import { Input, DialogTitle, PrimaryButton, TextButton } from '../../../ui';
import { getError } from '../../DataSources/helpers';
import { ModalDialog, INVITE_USER } from '../../Dialogs';
import { useStyles } from './useStyles';

export const InviteUser: React.FC = () => {
  const { register, onSubmit, errors, onCancel } = useInviteUser();
  const { controller } = useStyles();
  const hasErrors = getError(errors);

  return (
    <>
      <ModalDialog id={INVITE_USER}>
        <DialogTitle>Invite user</DialogTitle>
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
          <Input
            ref={register}
            className={controller}
            fullWidth
            label="User email"
            name="email"
            error={hasErrors('email')}
          />
          <PrimaryButton fullWidth type="submit" className={controller}>
            Invite
          </PrimaryButton>
        </form>
        <TextButton onClick={onCancel} type="reset">
          Cancel
        </TextButton>
      </ModalDialog>
    </>
  );
};
