import { useQuery, gql } from '@apollo/client';
import { QueryHook, ViewApiDef } from '../../../types';

const VIEW_API_DEF = gql`
  query getApiDefById($apiDefId: String!) {
    getApiDefById(id: $apiDefId) {
      name
      endpoint
      enabled
    }
    getGateways {
      listenHostname
      listenPort
      servername
    }
    getOrganization {
      apiNamespace
    }
  }
`;

export const useApiDefView: QueryHook<ViewApiDef> = (options = {}) => {
  const { data, loading, error, refetch } = useQuery(VIEW_API_DEF, options);

  return {
    data: data || {},
    loading,
    error,
    refetch,
  };
};
